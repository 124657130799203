@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  display: flex;
  align-items: normal;
  width: 302px;
  height: 226px;
  max-width: 100%;
  background: inherit;
  position: relative;
  cursor: pointer;
  margin-left: 24px;
  filter: none;
  transition: filter 0.3s linear;

  &:hover,
  &.focused {
    filter: drop-shadow(0px 2px 16px palette.black(10, "old"));
    transition: filter 0.3s linear;
    overflow: visible;

    .popover {
      svg {
        opacity: 1;
      }
    }
  }

  .topLabel {
    width: 151px;
    height: 36px;
    background: linear-gradient(0deg, palette.orange(064), palette.orange(064)),
      palette.white(100);
    border-radius: 8px 8px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.isDragOver {
      background: linear-gradient(
          0deg,
          palette.orange(064),
          palette.orange(064)
        ),
        palette.white(100);
    }
  }

  .middleLabel {
    width: 270px;
    height: 18px;
    background: linear-gradient(0deg, palette.orange(024), palette.orange(024)),
      palette.white(100);
    border-radius: 8px 8px 0 0;
    position: absolute;
    top: 18px;
    left: 16px;
    z-index: 2;

    &.isDragOver {
      background: linear-gradient(
          0deg,
          palette.orange(064),
          palette.orange(064)
        ),
        palette.white(100);
    }
  }

  .body {
    width: 302px;
    height: 190px;
    position: absolute;
    z-index: 3;
    top: 36px;
    left: 0;
    background: linear-gradient(0deg, palette.orange(064), palette.orange(064)),
      palette.white(100);
    border-radius: 0 8px 8px 8px;
    @include anyText(palette.white(032), 84px, 800, 104px);
    @include flex-center;
    padding-bottom: 44px;
    box-sizing: border-box;
    user-select: none;
    max-width: 302px;

    &.isDragOver {
      background: linear-gradient(
          0deg,
          palette.orange(064),
          palette.orange(064)
        ),
        palette.white(100);
    }

    .count {
      max-width: 270px;
      @include text-overflow;
    }
  }

  .footer {
    width: 302px;
    height: 52px;
    background: linear-gradient(0deg, palette.orange(032), palette.orange(032)),
      palette.white(100);
    border-radius: 0 0 8px 8px;
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    padding: 18px 16px 17px;
    box-sizing: border-box;
    @include flex-center;
    justify-content: space-between;

    &.isDragOver {
      background: linear-gradient(
          0deg,
          palette.orange(064),
          palette.orange(064)
        ),
        palette.white(100);
    }

    .name {
      @include anyText(palette.grey(100), 14px, 700, 17px);
      @include text-overflow;
      max-width: 182px;
      width: 100%;
      user-select: none;
    }

    .modified {
      @include anyText(palette.grey(032), 12px, 700, 16px);
      user-select: none;
    }
  }
}

.content {
  background-color: palette.white(100);
  position: absolute;
  width: fit-content;
  height: 64px;
  padding-top: 8px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px palette.black(11, "old");
  border-radius: 4px;
  right: calc(100% - 87px);

  &.uk {
    width: fit-content;
    right: calc(100% - 87px);
  }

  &.en {
    width: 139px;
    right: calc(100% - 67px);
  }

  .triangle {
    position: absolute;
    top: -5px;
    left: calc(50% - 3px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent palette.white(100) transparent;
    z-index: 7;
  }

  .contentBtns {
    display: flex;
    flex-direction: column;
  }
}

.popover {
  position: absolute;
  top: 47px;
  right: 16px;
  z-index: 10;
}

.icon {
  opacity: 0;

  &.open {
    opacity: 1;
  }
}

.popoverWrapper {
  position: absolute;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  @media screen and (max-height: 768px) {
    .root {
      width: 151px;
      height: 113px;
      margin-left: 15px;

      .popover {
        position: absolute;
        top: 22px;
        right: 8px;
        z-index: 10;
      }

      .topLabel {
        width: calc(151px / 2);
      }

      .middleLabel {
        width: calc(270px / 2);
        top: 9px;
        left: 10px;
      }

      .body {
        width: calc(302px / 2);
        height: calc(190px / 2);
        font-size: 46px;
        top: 18px;

        .count {
          max-width: calc(270px / 2);
        }
      }

      .footer {
        width: calc(302px / 2);
        height: calc(52px / 2);
        // bottosm: -18px;

        .modified {
          font-size: 11px;
          flex-shrink: 0;
        }

        .name {
          font-size: 12px;
        }
      }
    }
  }
}
