@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  display: flex;
  align-items: center;
  height: 53px;
  padding: 17px 16px 16px 16px;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  background-color: transparent;
  position: relative;

  .btn {
    box-sizing: border-box;
    border: none;
    background: inherit;
    outline: none;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.left {
      transform: rotate(180deg);
    }

    &.fileSystem {
      position: absolute;
      right: 50px;
    }

    &.disabled {
      cursor: not-allowed;

      .icon {
        path {
          stroke: palette.grey(23, "old") !important;
        }
      }
    }
  }

  .text {
    @include anyText(palette.black(2, "old"), 12px, 700, 20px);
    text-align: center;

    &.filesystem {
      color: palette.grey(30, "old");
    }
  }
}

.maxPages {
  transition: all 0.4s ease;
  &.loading {
    transition: all 0.4s ease;
    opacity: 0.5;
  }
}
