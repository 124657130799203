.react-responsive-modal-modal {
  background: transparent;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.modal-800 {
  max-width: 800px;
  overflow-y: visible !important;
}

.modal-response {
  max-width: 100% !important;
}
