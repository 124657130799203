@use "src/styles/palette";
@import "src/styles/mixins.scss";

.arrow {
  opacity: 0;
}

.root {
  &.withArrow {
    .rc-tooltip-arrow {
      display: block !important;
    }
  }

  &.white {
    opacity: 1 !important;
    .rc-tooltip-inner {
      background: palette.white(100) !important;
      color: palette.black(2, "old") !important;
      padding: 12px 16px 8px 16px !important;
      box-shadow: 0 4px 16px rgba(29, 29, 29, 0.16) !important;
      @include anyText(palette.grey(30, "old"), 12px, 400, 24px);
      border-radius: 4px !important;
    }

    .rc-tooltip-arrow {
      border-bottom-color: palette.white(100) !important ;
    }
  }
}

.light {
  .rc-tooltip-inner {
    background: linear-gradient(
        0deg,
        palette.blue(5, "old"),
        palette.blue(5, "old")
      ),
      palette.white(100) !important;
    color: palette.black(2, "old") !important;
    font-size: 12px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    letter-spacing: 0.03em !important;
    padding: 12px 16px !important;
    min-height: auto !important;
    box-sizing: border-box !important;
  }

  .rc-tooltip-arrow {
    width: 0 !important;
    height: 0 !important;
    border-style: solid !important;
    border-width: 0 7.91px 8px 7.91px !important;
    border-color: transparent transparent palette.blue(4, "old") transparent !important;
    top: 1px !important;
  }
}
