@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  max-width: 1280px;
  width: calc(100% - 28px);
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 2fr 2fr 42px;
  column-gap: 12px;
  padding: 4px 0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 4px;
  height: 32px;
  background-color: $white_color;

  &:hover,
  &.focused {
    background-color: palette.white(2, "old");

    svg {
      opacity: 1;
    }
  }

  .info {
    @include align-center;
    column-gap: 9px;
    @include text-overflow;
    padding-left: 10px;
    box-sizing: border-box;

    .icon {
      flex-shrink: 0;
    }

    .name {
      @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
      @include text-overflow;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .modified {
    @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
  }
}

.action {
  box-sizing: border-box;
  padding-top: 3px;
}
