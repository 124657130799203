@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  width: 302px;
  height: 226px;
  background: palette.white(10, "old");
  box-sizing: border-box;
  border-radius: 9px;
  border: 1px solid palette.white(10, "old");
  position: relative;
  margin-left: 24px;
  transition: filter 0.3s linear;

  .statusContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-left: auto;
  }

  &:hover,
  &.focused {
    filter: drop-shadow(0px 2px 16px palette.black(10, "old"));
    transition: filter 0.3s linear;

    .modals {
      svg {
        opacity: 1;
      }
    }
  }

  .preloader {
    position: absolute;
    width: 302px;
    height: 226px;
    box-sizing: border-box;
    z-index: 3;
    @include flex-center;
    flex-direction: column;
    background: palette.grey(32, "old");
  }

  .body {
    width: 100%;
    position: relative;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-color: palette.white(11, "old");
    background-size: auto;

    .actionBtn {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      opacity: 0.8;
      width: 30px;
      height: 24px;
      z-index: 10;

      &.clicked {
        .icon {
          fill: palette.white(2, "old");
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .footer {
    width: 100%;
    height: 52px;
    @include align-center;
    column-gap: 8px;
    padding: 0 0 0 16px;
    box-sizing: border-box;
    background: linear-gradient(0deg, palette.blue(016), palette.blue(016)),
      palette.white(100);
    border-radius: 0 0 8px 8px;

    .footerWrapper {
      display: flex;
      flex-direction: column;
      max-width: 180px;
      width: 100%;
      column-gap: 3px;

      .name {
        @include anyText(palette.grey(400), 14px, 700, 20px);
        @include text-overflow;
        max-width: 180px;
        width: 100%;
      }

      .categories {
        max-width: 180px;
        width: 100%;
        @include align-center;
        @include anyText(rgba(palette.grey(100), 0.5), 12px, 400, 16px);

        .categoryList {
          @include text-overflow;
          max-width: calc(100% - 30px);
        }
      }
    }
  }
}

.date {
  @include anyText(rgba(palette.grey(100), 0.72), 12px, 400, 15px);
  margin: 0 16px 0 auto;
  text-align: right;
}

.preview {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 138px;
}

.topLevel {
  background: linear-gradient(0deg, palette.blue(016), palette.blue(016)),
    palette.white(100);
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 36px;
  @include align-center;
  padding: 0 16px;
  box-sizing: border-box;

  .services {
    @include align-center;
    column-gap: 4px;

    .serviceCount {
      @include anyText(palette.grey(072), 12px, 400, 15px);
    }
  }

  .status {
    @include anyText(palette.grey(072), 12px, 400, 15px);
  }
}

.content {
  background-color: palette.white(100);
  width: fit-content;
  border-radius: 4px;

  .contentBtns {
    display: flex;
    flex-direction: column;
  }
}

.dropdownItem {
  min-width: 104px;
  padding: 0 16px 0 19px;
  height: 24px;
  background-color: inherit;
  border: none;
  outline: none;
  box-sizing: border-box;

  > div {
    max-width: inherit;
    @include anyText(palette.grey(30, "old"), 12px, 400, 16px);
  }

  &:hover {
    background-color: palette.blue(008);
    cursor: pointer;

    > div {
      @include anyText(palette.black(2, "old"), 12px, 400, 16px);
    }
  }
}

.modals {
  svg {
    opacity: 0;
  }
}

.emptyScenario {
  height: 138px;
  width: 300px;
  background-color: palette.white(10, "old");
  @include flex-center;

  svg {
    width: 91.5px;
    height: 81.33px;
  }
}

.extraCategories {
  padding: 0 3px;
  box-sizing: border-box;
  @include anyText(palette.grey(072), 12px, 400, 16px);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1024px) {
  @media screen and (max-height: 768px) {
    .root {
      width: calc(302px / 2);
      height: calc(226px / 2);
      margin-left: 15px;

      .footer {
        flex-direction: row;
        height: 36px;

        .footerWrapper {
          max-width: 50%;
          .name {
            font-size: 12px;
            line-height: 1;
          }

          .categories {
            .categoryList {
              max-width: 100%;
            }
          }
        }
      }
    }

    .preview {
      height: 50px;
    }

    .date {
      margin: 0 auto 0 0px;
      font-size: 10px;
      width: 100%;
      padding-right: 5px;
      text-align: left;
      line-height: 1;
    }

    .topLevel {
      height: 26px;
    }

    .emptyScenario {
      height: 50px;
      width: 149px;

      svg {
        width: calc(91.5px / 2);
        height: calc(81.33px / 2);
      }
    }
  }
}
