@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root,
.root:first-of-type {
  @include anyText(palette.grey(30, "old"), 16px, 500, 20px);
  opacity: 0.8;
  max-width: 150px;
  @include text-overflow;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    opacity: 1;
    color: palette.blue(100);
  }
}
