@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  @include align-center;
  column-gap: 4px;
  overflow: hidden;

  .serviceCount {
    @include anyText(palette.grey(072), 12px, 400, 15px);
  }
}
