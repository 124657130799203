@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  @include align-center;
  cursor: pointer;
  user-select: none;

  &:hover {
    .checkbox {
      border: 2px solid palette.blue(100);
      border-radius: 5px;
    }
  }

  .checkbox {
    background: inherit;
    width: 24px;
    height: 24px;
    border: 2px solid palette.grey(31, "old");
    border-radius: 5px;
    transition: all 0.5s ease;
    margin: 0 8px 0 0;
    @include flex-center;

    &:hover {
      border: 2px solid palette.blue(100);
      border-radius: 5px;
    }
  }

  .checked {
    background: palette.blue(100);
    border: 2px solid palette.blue(100);
  }
}
