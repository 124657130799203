@use "src/styles/palette";
@import "src/styles/mixins.scss";

.content {
  background-color: palette.white(100);
  width: fit-content;
  border-radius: 4px;

  .contentBtns {
    display: flex;
    flex-direction: column;
  }
}

.icon {
  &.open {
    opacity: 1;
  }
}

.dropdownItem {
  min-width: 104px;
  padding: 0 16px 0 19px;
  height: 24px;
  background-color: inherit;
  border: none;
  outline: none;
  box-sizing: border-box;

  > div {
    max-width: inherit;
    @include anyText(palette.grey(30, "old"), 12px, 400, 16px);
  }

  &:hover {
    background-color: palette.blue(008);
    cursor: pointer;

    > div {
      @include anyText(palette.black(2, "old"), 12px, 400, 16px);
    }
  }
}

.popup {
  width: 0;
  height: 0;
}
