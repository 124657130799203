@use "src/styles/palette";
@import "src/styles/mixins.scss";

.button {
  width: 100%;
  height: 56px;
  @include title-19px;
  color: palette.white(100);
  @include flex-center;
  border-radius: 8px;
  background: palette.black(2, "old");
  transition: all 0.5s ease;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  letter-spacing: 0.03em;
  outline: none;

  &:disabled {
    background: palette.grey(33, "old");
    outline: none;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    background: palette.black(12, "old");
  }
}

.root {
  position: relative;
  // width: 100%;
}

.spinner {
  width: 100%;
  height: 56px;
  position: absolute;
  top: 0;
  left: 0;
  @include flex-center;
}

.black {
  background: palette.black(2, "old");

  &:hover:not([disabled]) {
    background: palette.white(22, "old");
    color: palette.blue(100);
  }
}

.blue {
  background: palette.blue(100);

  &:hover {
    background: palette.white(22, "old");
  }
}

.whiteBlue {
  background: palette.white(100);
  color: palette.blue(100);

  &:hover {
    background: palette.white(22, "old");
  }
}

.light {
  @include linearGradient;
  color: palette.blue(100);
}

.white {
  background: palette.white(100);
  color: palette.red(2, "old");
  border: 1px solid palette.grey(17, "old");
}

.invisible {
  background: transparent;

  &:hover {
    background: transparent;
  }

  &:disabled {
    background: transparent;
    outline: none;
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    background: transparent;
  }
}
