@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  width: 202px;
  height: 112px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: palette.white(100);
  position: relative;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 8px;
    pointer-events: none; /* Чтобы граница не вмешивалась в события мыши */
  }

  &.error.selected::before,
  &.error.selected::after {
    border-color: palette.red(100);
  }

  &.selected::after,
  &.selected::before {
    border-color: palette.blue(100);
  }

  &::before {
    z-index: 1;
  }

  &.grey {
    .header {
      background: var(
        --grey-16,
        linear-gradient(
          0deg,
          rgba(48, 55, 87, 0.16) 0%,
          rgba(48, 55, 87, 0.16) 100%
        ),
        #fff
      );
    }
  }

  .header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    padding: 10px 12px;
    box-sizing: border-box;
    background-color: rgba($color: palette.blue(100), $alpha: 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    position: relative;

    .block {
      width: 120px;
      @include anyText(
        rgba($color: palette.grey(100), $alpha: 0.8),
        12px,
        400,
        15px
      );
    }
  }

  .icon {
    display: none;

    &.open {
      display: block;
    }
  }

  &:hover,
  &.error:hover {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    transition: box-shadow 0.3s ease;

    .icon {
      display: block;
    }
  }

  &.error {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08);
    background-color: rgba($color: palette.red(100), $alpha: 0.08);
    transition: box-shadow 0.3s ease;

    .body {
      height: 72px;
    }
  }

  &.success {
    .header {
      background-color: rgba($color: palette.green(100), $alpha: 0.08);
    }
  }

  .body {
    padding: 16px 12px;
    box-sizing: border-box;
    background-color: palette.white(100);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 64px;

    .top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;
      margin-bottom: 4px;
      height: 17px;
    }

    .bottom {
      @include anyText(
        rgba($color: palette.grey(100), $alpha: 0.8),
        12px,
        400,
        15px
      );

      max-width: 145px;
      @include text-overflow;
      margin-left: 28px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.controlls {
  position: absolute;
  width: 282px;
  height: 192px;
  top: -40px;
  left: -40px;
  transition: opacity 0.3s ease;

  &.withoutLeft {
    width: 242px;
    height: 192px;
    left: 0;

    .controlItem {
      &.top,
      &.bottom {
        left: calc(50% - 40px);
      }
    }
  }

  .alreadyCreated {
    .wrapper {
      opacity: 0;
      background-color: #f7f7f8;
      box-shadow: 0px 0px 227px -53px rgba(0, 0, 0, 0.75);
      z-index: -1;
      // pointer-events: auto;
      .control {
        z-index: 1;
      }
    }
  }

  &:hover {
    .alreadyCreated {
      .wrapper {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }
}

.controlItem {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  color: palette.white();
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .wrapper {
      .control {
        width: 24px;
        height: 24px;
      }
    }
  }

  .wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .control {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: palette.white(100);
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.invisible {
    display: none;
  }

  &.top {
    top: 0;
    left: calc(50% - 23px);
  }

  &.bottom {
    bottom: 0;
    left: calc(50% - 23px);
  }

  &.left {
    left: 0;
    top: calc(50% - 23px);
  }

  &.right {
    right: 0;
    top: calc(50% - 23px);
  }

  &.createNew {
    .control {
      background-color: palette.blue(100);
    }
  }

  &.alreadyCreated {
    .control {
      background-color: palette.orange(100);
    }
  }
}

.name {
  @include anyText(palette.grey(100), 13px, 700, 16px);
  @include text-overflow;
  width: 100%;
  pointer-events: none;
}

.counter {
  width: 20px;
  height: 20px;

  path {
    fill: palette.blue(100);
  }

  &.grey {
    path {
      fill: palette.grey(016);
    }
  }
}

.execute {
  path {
    stroke: palette.blue(100);
  }

  &.grey {
    path {
      stroke: palette.grey(072);
    }
  }
}

.webhook {
  width: 20px;
  height: 20px;

  path {
    stroke: palette.blue(100);
  }

  &.grey {
    path {
      stroke: palette.grey(072);
    }
  }
}

.blockIcon {
  &.grey {
    path {
      fill: palette.grey(072);
    }
  }
}

.attentionIcon {
  &.grey {
    path {
      stroke: palette.grey(072);
    }
  }
}

.panel {
  width: 125px;
  border-radius: 4px;
  background-color: palette.white(100);
  position: absolute;
  left: calc(100% - 30px);
  top: 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px 0px #1b243d29;
  z-index: 1;
}

// .menu {
//   &:hover div {
//     // pointer-events: none;
//   }
// }
