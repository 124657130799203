@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  width: 100%;
  margin: 35px auto 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 155px);
  max-width: 1344px;

  .header {
    @include align-center;
    justify-content: space-between;
    margin: 0 0 16px 0;

    .routeName {
      @include anyText(palette.grey(30, "old"), 16px, 500, 20px);
      user-select: none;
      @include text-overflow;
    }

    .headerActions {
      @include align-center;
      column-gap: 15px;
      margin-right: 35px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: palette.white(100);
    width: 100%;
    height: auto;
    padding: 24px 0 60px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
  }
}

.icon {
  cursor: pointer;

  path {
    stroke: palette.grey(30, "old");
  }

  &.selected {
    path {
      stroke: palette.blue(100);
    }
  }
}

.wrapperContent {
  height: calc(100vh - 300px);
  overflow: hidden;
  flex-shrink: 0;
  overflow: auto;
  position: relative;

  &.load {
    .content {
      height: calc(100% - 1px);
    }
  }

  > div {
    transform: none !important;
  }

  &::after {
    content: "";
    width: calc(100vw - 120px);
    max-width: 1280px;
    position: absolute;
    height: 1px;
    background-color: palette.grey(17, "old");
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: 0;
  }

  &.globalSearchWrapper {
    &::after {
      opacity: 0;
    }
  }

  &.table {
    height: calc(100vh - 370px);

    &.globalSearchWrapper {
      overflow-y: auto;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    background-color: palette.white(100);
    width: 100%;
    overflow-y: auto;
    @include scrollbar;
    padding: 24px 0 24px 0;
    box-sizing: border-box;
    position: relative;

    &.table {
      display: block;
      height: 100%;
      padding-left: 21px;
    }
  }
}

.actions {
  margin: 0 32px 0 auto;
  @include align-center;
  column-gap: 20px;
  flex-shrink: 0;

  .createFolder,
  .createScenario {
    border-radius: 9px;
    border: none;
    outline: none;
    padding: 10px 16px 11px 16px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.03em;
    color: palette.blue(100);
    cursor: pointer;
    @include linearGradient;
    @include flex-center;
  }
}

.loading {
  width: 126px;
  height: 126px;
  background: transparent;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallAction {
  display: none;
}

@media screen and (max-width: 1024px) {
  .smallAction {
    display: block;
    height: 20px;
  }

  .action {
    display: none;
  }
}

.pagination {
  width: 190px;
  margin: 7px 18px 0 auto;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.draggableItem {
  display: flex;
  flex-wrap: wrap;
  width: 326px;
  height: 226px;

  &.table {
    width: 100%;
    height: 32px;
    > div {
      width: 100%;
      height: 32px;
    }
  }
}

.back {
  width: 302px;
  height: 226px;
  background-color: palette.blue(100);
  border-radius: 8px;
  @include flex-center;
  @include anyText(palette.white(100), 16px, 800, 20px);
  margin-left: 24px;

  &.table {
    margin-left: 0;
    margin-right: 24px;
    width: 100%;
    height: 60px;
    margin: 10px 0 0 0;
  }
}

.globalSearchItem {
  border-bottom: 4px solid palette.blue(008);
  width: calc(100% - 32px);
}

.globalSearch {
  padding-left: 20px;
  box-sizing: border-box;
}

.pathContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
  padding: 16px 0;
  box-sizing: border-box;

  .pathWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .pathName {
    @include anyText(palette.grey(056), 15px, 400, 20px);
    max-width: 100px;
    @include text-overflow;
  }

  .pathIcon {
    path {
      stroke: palette.grey(056);
    }
  }
}

.parent {
  height: fit-content;

  &.table {
    width: 100%;
    height: 70px;
  }

  &.invisible {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 1024px) {
  @media screen and (max-height: 768px) {
    .root {
      .wrapper {
        padding-top: 10px;
      }
    }
  }
}
