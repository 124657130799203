@use "src/styles/palette";
@import "src/styles/mixins.scss";

.btn {
  border: 1px solid palette.grey(17, "old");
  background: inherit;
  box-sizing: border-box;
  border-radius: 6px;
  width: 90px;
  height: 32px;
  outline: none;
  @include flex-center;
  opacity: 1;

  &:disabled {
    border: 1px solid palette.white(15, "old");

    .btnText {
      color: palette.grey(17, "old");
    }

    .arrowLeft {
      fill: palette.grey(17, "old");
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .arrowLeft {
    margin: 0 10px 0 0;
    fill: palette.blue(100);
  }

  .btnText {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    @include align-center;
    letter-spacing: 0.03em;
    color: palette.blue(100);
  }
}

.root {
  @include align-center;
  max-width: calc(100% - 150px);
  overflow: hidden;
}

.rout {
  @include align-center;

  .slash {
    margin: 0 8px;
    transform: rotate(180deg);
    width: 8px;
    height: 10px;

    path {
      fill: palette.grey(31, "old");

      &:last-child {
        fill: palette.blue(100);
      }
    }
  }
}

.routeContent {
  @include align-center;
  flex-wrap: wrap;
  row-gap: 10px;
}
