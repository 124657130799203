.root {
  width: 100%;
  height: 100%;
  background: inherit;
  overflow: hidden;
  border-radius: 16px;

  .spinner {
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 17px);
    z-index: 100;
  }
}