@use "src/styles/palette";

.trigger {
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  max-width: 100%;

  &.withoutArrow {
    padding: 0;
  }
}

.icon {
  width: 8px;
  height: 5px;
  flex-shrink: 0;
  transition: transform 0.2s ease-in;
  margin-left: auto;

  &.open {
    transition: transform 0.2s ease-in-out;
    transform: translate(0px, 4px);

    &.rotateArrowUp {
      transform: rotate(180deg);
    }
  }
}

.anchor {
  display: flex;
  align-items: center;
}

.content {
  outline: none;
  z-index: 1000;
}

.arrow {
  width: 12px;
  height: 6px;

  &.light {
    fill: palette.white(100);
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 12px 0;
  box-sizing: border-box;
  background: palette.grey(400);
  border-radius: 4px;

  &.light {
    background: palette.white(100);
    box-shadow: 0 4px 16px palette.black(11, "old");
  }

  &.dark {
    background: palette.grey(400);
  }
}
