@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  @include flex-center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100000000;
  left: 0;
  top: 0;
  background: palette.white(100);
  transition: 1s ease all;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid palette.white(3, "old");
  border-right: 1.1em solid palette.white(3, "old");
  border-bottom: 1.1em solid palette.white(3, "old");
  border-left: 1.1em solid palette.orange(2, "old");
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
