@use "src/styles/palette";
@import "src/styles/mixins.scss";

.text {
  margin: 0 8px;
  min-width: 125px;
  text-align: left;
}

.hotKey,
.text {
  box-sizing: border-box;
  @include anyText(palette.white(100), 12px, 600, 16px);
  @include text-overflow;
  margin-right: 4px;

  &.selected {
    color: palette.blue(100);
  }
}
