@use "src/styles/palette";
@import "src/styles/mixins.scss";

html,
body {
  height: 100%;
}

body,
button,
textarea {
  font-family: Lato;

  -webkit-font-smoothing: atialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: flex-end;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.shrink-0 {
  flex-shrink: 0;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 1;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-l-0 {
  margin-left: 0;
}

.m-r-0 {
  margin-right: 0;
}

.full-width {
  width: 100%;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-static {
  position: static;
}

.fadeIn {
  animation: fadein 1.2s ease;
  animation-iteration-count: 1;
}

@keyframes fadein {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0.8;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOut 1s ease;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.App {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  @include scrollbar;
}

@import "node_modules/sweetalert2/src/variables"; // eslint-disable-line

$swal2-dark-theme-black: #19191a;
$swal2-dark-theme-white: #e1e1e1;
$swal2-outline-color: lighten($swal2-outline-color, 10%);

$swal2-background: $swal2-dark-theme-black;
$swal2-content-color: $swal2-dark-theme-white;
$swal2-title-color: $swal2-dark-theme-white;
$swal2-backdrop: rgba($swal2-dark-theme-black, 0.75);

// FOOTER
$swal2-footer-border-color: #555;
$swal2-footer-color: darken($swal2-content-color, 15%);

// TIMER POGRESS BAR
$swal2-timer-progress-bar-background: rgba($swal2-dark-theme-white, 0.6);

// INPUT
$swal2-input-color: $swal2-dark-theme-white;
$swal2-input-background: lighten($swal2-dark-theme-black, 10%);

// VALIDATION MESSAGE
$swal2-validation-message-background: lighten($swal2-dark-theme-black, 10%);
$swal2-validation-message-color: $swal2-dark-theme-white;

// QUEUE
$swal2-progress-step-background: lighten($swal2-dark-theme-black, 25%);

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-focus-box-shadow: 0 0 0 1px $swal2-background,
  0 0 0 3px $swal2-outline-color;

// TOAST
$swal2-toast-background: $swal2-dark-theme-black;
$swal2-toast-button-focus-box-shadow: 0 0 0 1px $swal2-background,
  0 0 0 3px $swal2-outline-color;

@import "node_modules/sweetalert2/src/sweetalert2"; // eslint-disable-line

body.swal2-toast-shown .swal2-container {
  width: auto !important;
}
.black-popup {
  background: palette.black(2, "old") !important;
  border-radius: 13px !important;
  flex-direction: inherit !important;
  align-items: center !important;
  padding: 10px 70px 10px 60px !important;
  display: flex !important;
  width: auto !important;
  max-width: auto !important;
  column-gap: 3px !important;
}

.html-container-popup {
  color: palette.white(100, "new") !important;
  margin: 7px 0 7px 0 !important;
}

.confirm-btn-popup {
  position: absolute !important;
  right: 16px !important;
  top: 50% !important;
  transform: translate(6px, -50%) !important;
  margin: 0 !important;
  background-color: palette.blue(100) !important;
}

.icon-popup {
  position: absolute !important;
  left: 10px !important;
}

.title-popup {
  letter-spacing: 0.03em !important;
  color: palette.white(100) !important;
  margin: 7px 0 7px 5px !important;
}

.content-popup {
  letter-spacing: 0.03em !important;
  color: palette.white(100) !important;
  margin-left: 10px !important;

  .swal2-html-container {
    padding: 0 !important;
  }
}

body {
  @include scrollbar;
}

// .rc-tooltip {
//   opacity: 1 !important;
// }

.rc-tooltip-arrow {
  display: none !important;
}

.rc-tooltip-inner {
  padding: 8px 10px 0 10px !important;
  color: palette.white(100) !important;
  text-align: left !important;
  text-decoration: none !important;
  background-color: #373737 !important;
  border-radius: 6px !important;
  min-height: 23px !important;
  max-width: 250px !important;
  word-break: break-all;
}

.react-responsive-modal-container {
  backdrop-filter: blur(2px);
}

.bpm-alert-container {
  backdrop-filter: blur(2px);
  font-family: Lato !important;

  .bpm-alert-popup {
    background: palette.white(100);
    box-shadow: 0, 4px, 10px, -3px, rgba(0, 0, 0, 0.08%);
    min-width: 350px !important;
    width: auto !important;
    border-radius: 16px;

    .swal2-header {
      .swal2-title {
        color: palette.black(2, "old");
        margin-bottom: 20px;
        font-family: "Lato" !important;
      }
    }

    .swal2-content {
      .swal2-html-container {
        color: palette.black(2, "old");
        margin-bottom: 30px;
        font-size: 14px;
      }
    }
  }
}

.tooltip-scenario-header .rc-tooltip-inner {
  padding: 0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  background-color: none !important;
  opacity: 1 !important;
}

.radix-popover-anchor:focus {
  outline: none !important;
}

input[type="password"] {
  /* Removing the default "eye" icon in Firefox */
  -moz-appearance: none !important;
}

input[type="password"]::-ms-reveal {
  display: none !important;
}

.css-qbdosj-Input {
  position: absolute !important;
  top: 2px;
}

.dark-title-popup {
  color: palette.grey(100);
}
