@use "src/styles/palette";
@import "src/styles/mixins.scss";

.content {
  background-color: palette.white(100);
  position: absolute;
  width: fit-content;
  height: 64px;
  padding-top: 8px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px palette.black(11, "old");
  border-radius: 4px;
  right: calc(100% - 87px);

  &.uk {
    width: fit-content;
    right: calc(100% - 87px);
  }

  &.en {
    width: 139px;
    right: calc(100% - 67px);
  }

  .triangle {
    position: absolute;
    top: -5px;
    left: calc(50% - 3px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent palette.white(100) transparent;
    z-index: 7;
  }

  .contentBtns {
    display: flex;
    flex-direction: column;
  }
}

.popover {
  position: absolute;
  top: 47px;
  right: 16px;
  z-index: 10;
}

.icon {
  opacity: 0;

  &.open {
    opacity: 1;
  }
}

.dropdownItem {
  min-width: 104px;
  padding: 0 16px 0 19px;
  height: 24px;
  background-color: inherit;
  border: none;
  outline: none;
  box-sizing: border-box;

  > div {
    max-width: inherit;
    @include anyText(palette.grey(30, "old"), 12px, 400, 16px);
  }

  &:hover {
    background-color: palette.blue(008);
    cursor: pointer;

    > div {
      @include anyText(palette.black(2, "old"), 12px, 400, 16px);
    }
  }
}

.popoverWrapper {
  margin-top: 3px;
}
