@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  padding: 38px 48px 48px 48px;
  box-sizing: border-box;
  background-color: palette.white(100);
  max-width: 552px;
  width: 100%;
  min-height: 504px;
  box-shadow: 0px 24px 32px 0px rgba(31, 41, 105, 0.08);
  border-radius: 32px;

  .title {
    @include anyText(palette.grey(100), 45px, 900, 55px);
    margin-bottom: 48px;
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}
