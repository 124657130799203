/* @import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap&subset=cyrillic); */
/* // https://fonts.adobe.com/fonts/lato */
/* @import url(https://use.typekit.net/igl2hla.css); */
/* @import url(https://p.typekit.net/p.css?s=1&k=igl2hla&ht=tk&f=15700.15701.15702.15705.15708.15709.22708.22710.22736.22741&a=68076515&app=typekit&e=css); */

@font-face {
  font-family: "Lato", ;
  /* src: url(./assets/fonts/Lato-Black.eot); */
  src: url(./assets/fonts/Lato-Black.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Black.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Black.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 900;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Heavy.eot); */
  src: url(./assets/fonts/Lato-Heavy.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Heavy.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Heavy.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 800;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Bold.eot); */
  src: url(./assets/fonts/Lato-Bold.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Bold.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Bold.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Semibold.eot); */
  src: url(./assets/fonts/Lato-Semibold.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Semibold.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Semibold.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Medium.eot); */
  src: url(./assets/fonts/Lato-Medium.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Medium.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Medium.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 500;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Regular.eot); */
  src: url(./assets/fonts/Lato-Regular.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Regular.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Regular.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Light.eot); */
  src: url(./assets/fonts/Lato-Light.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Light.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Light.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Thin.eot); */
  src: url(./assets/fonts/Lato-Thin.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Thin.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Thin.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 200;
}

@font-face {
  font-family: "Lato";
  /* src: url(./assets/fonts/Lato-Hairline.eot); */
  src: url(./assets/fonts/Lato-Hairline.woff2) format("woff2");
  /* url(./assets/fonts/Lato-Hairline.woff) format("woff"); */
  /* url(./assets/fonts/Lato-Hairline.ttf) format("truetype"); */
  font-display: swap;

  font-weight: 100;
}

body,
button {
  font-family: Lato, sans-serif;

}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
input:-internal-autofill-selected,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #1d1d1d;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.input:-webkit-autofill:not(:focus) {
  -webkit-text-fill-color: #1d1d1d;
  -webkit-box-shadow: 0 0 0 30px #f8f8f9 inset !important;
  box-shadow: 0 0 0 30px #f8f8f9 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}