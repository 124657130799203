@use "src/styles/palette";
@import "./src/styles/mixins.scss";

.root {
  width: calc(100% - 28px);
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 2fr 2fr 42px;
  column-gap: 12px;
  border-bottom: 1px solid palette.blue(100);
  padding: 32px 0 16px;
  box-sizing: border-box;
  margin: 0 0 0 21px;
  width: calc(100% - 55px);

  .item {
    @include anyText(palette.grey(31, "old"), 14px, 500, 20px);
    box-sizing: border-box;

    &:first-child {
      padding-left: 30px;
    }
  }
}
