//Palette
$orange_color: #f3ba27;
$blue_color: #2084e1;
$blue_light_color: #5d9ef3;
$blue_dark_color: #347cbf;
$red_color: #f50041;
$green_color: #11bd37;
$grey_color: #303757;
$grey_dark_color: #1b243d;
$white_color: #ffffff;
$text_color: #000;
$site_bg: #e1e4e6;
$main_color: #1d1d1d;
$secondary_color: #595959;
$grey_color_2: #e9e9e9;

$border_radius: 8px;
$secondary_light_color: linear-gradient(
    0deg,
    rgba(236, 237, 239, 0.4),
    rgba(236, 237, 239, 0.4)
  ),
  #ffffff;
$site_height: calc(100vh - 80px);
$debt_site_height: calc(100vh - 120px);
$blue_gradient: linear-gradient(
    0deg,
    rgba(32, 132, 225, 0.16),
    rgba(32, 132, 225, 0.16)
  ),
  #ffffff;

$sub_color: #949494;

$margins: (
  a: auto,
  0: 0px,
  2: 2px,
  3: 3px,
  5: 5px,
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  15: 15px,
  16: 16px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
  80: 80px,
  100: 100px,
);

$paddings: (
  0: 0px,
  5: 5px,
  10: 10px,
  15: 15px,
  16: 16px,
  20: 20px,
  30: 30px,
  40: 40px,
  50: 50px,
  60: 60px,
);
