@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  .text {
    font-size: 14px;
    margin: 0 0 12px 0;
    max-width: 377px;
    text-align: justify;
  }

  .box {
    width: 589px;
    // height: 692px;
    background: palette.white(100);
    box-shadow: 0 4px 10px -3px palette.black(4, "old");
    border-radius: 16px;
    flex-direction: column;
    padding: 42px;
    box-sizing: border-box;
    @include flex-center;

    .head {
      @include h1-title();
      margin: 0 0 24px 0;
    }

    .apply {
      width: 377px;
      height: 56px;
      @include primaryButton;
    }

    .cancel {
      width: 377px;
      margin: 8px 0 0 0;
      @include outlineSecondaryButton;
    }
  }
}
