@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  @include align-center;
  margin-right: auto;
  width: calc(100% - 280px);

  .count {
    @include anyText(palette.black(2, "old"), 32px, 900, 40px);
    max-width: 302px;
    width: 100%;
    @include text-overflow;
    flex-shrink: 0;
    margin-right: 50px;
  }

  .searchContent {
    position: relative;
    margin-right: 10px;
    max-width: 302px;
    min-width: 150px;
    width: 100%;

    .icon {
      position: absolute;
      top: 14px;
      left: 12px;
    }

    .crossIcon {
      position: absolute;
      right: 8px;
      top: 10px;
      cursor: pointer;
    }

    .search {
      width: 100%;
      max-width: 301px;
      height: 40px;
      border: 1px solid palette.blue(008);
      outline: none;
      border-radius: 4px;
      padding-left: 32px;
      padding-right: 10px;
      box-sizing: border-box;
      @include anyText(palette.black(2, "old"), 14px, 500, 20px);

      &::placeholder {
        @include anyText(palette.grey(31, "old"), 14px, 400, 20px);
      }

      &:hover,
      &:focus {
        transition: all 0.4s ease;
        border: 1px solid $blue_color;
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .root {
    .count {
      flex-shrink: 1;
    }
  }
}

@media screen and (max-width: 1000px) {
  .root {
    .count {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  @media screen and (max-height: 768px) {
    .root {
      .count {
        position: absolute;
        font-size: 14px;
        top: 1px;
        left: 26px;
      }
    }
  }
}
