@use "src/styles/palette";
@import "src/styles/mixins.scss";

.overlay,
.root {
  max-height: 380px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-right: 25px;

  &.edit {
    margin: 0;
    padding: 0 16px;

    .items {
      margin: 16px 0;
      box-sizing: border-box;
    }
  }
}

.overlay {
  margin-right: 0;
  > div:not(.buttons) {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    @include flex-center;
  }
}

.dropdownItem {
  @include align-center;
  column-gap: 4px;
  width: 100%;
  height: 24px;
  background: inherit;
  border: none;
  padding-left: 25px;
  outline: none;
  cursor: pointer;

  > div {
    @include anyText(palette.grey(30, "old"), 12px, 400, 24px);
  }

  > svg {
    flex-shrink: 0;
    path {
      stroke: palette.grey(30, "old");
    }
  }

  &:hover {
    background: palette.blue(008);
  }

  &.simple {
    padding-left: 36px;
  }

  &.edit {
    max-width: 270px;
    @include text-overflow;
    flex-shrink: 0;
  }

  &.selected {
    padding-left: 6px;
    box-sizing: border-box;
    > div {
      @include anyText(palette.grey(30, "old"), 12px, 700, 24px);
    }

    &.simple {
      padding-left: 16px;
    }
  }
}

.search {
  position: relative;
  width: 270px;
  height: 32px;

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  input {
    padding: 6px 12px 6px 32px;
    border: 1px solid palette.blue(008);
    border-radius: 4px;
    @include anyText(palette.grey(30, "old"), 12px, 500, 20px);
    outline: none;

    &::placeholder {
      @include anyText(palette.grey(31, "old"), 12px, 400, 20px);
    }
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 8px;

  .label {
    @include anyText(palette.grey(056), 15px, 400, 20px);
  }

  .text {
    @include anyText(palette.grey(30, "old"), 14px, 500, 20px);
    max-width: 130px;
    @include text-overflow;
  }
}

.items {
  max-height: 264px;
  height: 100%;
  overflow-y: auto;
  @include scrollbar;
}

.edit {
  @include anyText(palette.blue(100), 15px, 700, 20px);
  margin: 0 auto 0 0;
  cursor: pointer;
}

.clear {
  margin: 0 0 0 auto;
}

.arrowClassName {
  path {
    stroke: palette.blue(100);
  }
}
