@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  overflow: hidden;
  .box {
    width: 498px;
    // height: 692px;
    max-height: 710px;
    height: 100%;
    background: linear-gradient(
        0deg,
        palette.white(2, "old"),
        palette.white(2, "old")
      ),
      palette.white(100);
    /* 1 */

    box-shadow: 0px 4px 10px palette.black(4, "old");
    border-radius: 16px;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    @include flex-center;

    .title {
      @include anyText(palette.black(2, "old"), 32px, 900, 40px);
      margin-bottom: 12px;
      width: 100%;
    }

    .subtitle {
      @include anyText(palette.grey(30, "old"), 16px, 400, 22px);
      width: 100%;
      padding-bottom: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid palette.grey(100) _2;
      margin-bottom: 32px;
    }
  }

  .header {
    @include align-center;
    column-gap: 16px;
    margin-bottom: 16px;
    width: 100%;

    .search {
      max-width: 330px;
      width: 100%;
      height: 40px;
      padding: 10px 32px;
      box-sizing: border-box;
      background-color: palette.white(100);
      border-radius: 4px;
      border: 1px solid palette.blue(008);
      outline: none;
      @include anyText(palette.black(2, "old"), 14px, 500, 20px);

      &::placeholder {
        @include anyText(palette.grey(31, "old"), 14px, 400, 20px);
      }
    }
  }

  .body {
    width: 100%;
    height: 452px;
    padding: 24px;
    background-color: palette.white(100);
    box-sizing: border-box;
  }
}

.add {
  @include flex-center;
  @include anyText(palette.white(100), 16px, 800, 20px);
  padding: 8px 0;
  background-color: palette.blue(100);
  border-radius: 8px;
  min-width: 88px;
  column-gap: 8px;
  cursor: pointer;
  outline: none;
  border: none;

  svg {
    path {
      stroke: palette.white(100);
    }
  }
}

.arrow {
  transform: rotate(0deg);

  &.asc {
    transform: rotate(180deg);
  }
}

.bodyHeader {
  @include align-center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-right: 5px;
  box-sizing: border-box;

  .headerItem {
    @include align-center;
    column-gap: 5px;
    @include anyText(palette.grey(31, "old"), 14px, 400, 20px);
    cursor: pointer;
  }
}

.categories {
  min-height: 315px;
  height: 315px;
  width: 100%;
  overflow-y: auto;
  @include scrollbar;
  border-bottom: 1px solid palette.grey(100) _2;
  row-gap: 4px;
  padding-top: 20px;
  box-sizing: border-box;

  .category {
    @include align-center;
    justify-content: space-between;
    padding: 4px 6px 4px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent;
    transition: all 0.3s linear;
    margin-bottom: 4px;

    .name {
      @include anyText(palette.black(2, "old"), 14px, 700, 24px);
      max-width: 317px;
      @include text-overflow;
    }

    &:hover {
      background-color: palette.blue(008);
      transition: all 0.3s linear;

      .icon {
        opacity: 1;
      }
    }
    .icon {
      opacity: 0;

      &.open {
        opacity: 1;
      }
    }
  }
}

.dropdownItem {
  min-width: 104px;
  padding: 0 16px 0 19px;
  height: 24px;
  background-color: inherit;
  border: none;
  outline: none;
  box-sizing: border-box;

  > div {
    max-width: inherit;
    @include anyText(palette.grey(30, "old"), 12px, 400, 16px);
  }

  &:hover {
    background-color: palette.blue(008);
    cursor: pointer;

    > div {
      @include anyText(palette.black(2, "old"), 12px, 400, 16px);
    }
  }
}

.selected {
  background-color: inherit;
  @include anyText(palette.blue(100), 19px, 700, 14px);
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  width: 30px;
  @include flex-center;

  &:hover {
    background-color: transparent;
  }
}

.items {
  display: flex;
  flex-direction: column;
}

.actions {
  @include flex-center;
  column-gap: 16px;
  margin-top: 10px;

  .cancel,
  .apply {
    min-width: 192px;
    height: 56px;
    padding: 18px;
    box-sizing: border-box;
    border-radius: 8px;
    @include anyText($sub_color, 16px, 700, 20px);
    outline: none;
    cursor: pointer;
  }

  .cancel {
    background-color: inherit;
    border: 1px solid $sub_color;
  }

  .apply {
    color: $white_color;
    background-color: $blue_color;
    border: 1px solid $blue_color;
  }
}
