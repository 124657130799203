@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  width: 360px;
  height: 272px;
  position: absolute;
  top: calc(50vh - 272px);
  left: calc(50% - 189px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  column-gap: 15px;
  background: url(../../../../assets/png/stub_folder_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100;

  .text {
    @include anyText(
      rgba($color: palette.grey(100), $alpha: 0.72),
      18px,
      400,
      24px
    );
    cursor: default;
    text-align: center;

    &.withoutBtn {
      margin-bottom: 70px;
    }
  }

  .btn {
    margin-top: 20px;
    width: 128px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid palette.blue(100);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: palette.white(100);
    cursor: pointer;
    column-gap: 8px;

    &:hover {
      background-color: palette.blue(030);

      .btnText {
        color: palette.white(100);
      }

      svg {
        path {
          stroke: palette.white(100);
        }
      }
    }

    svg {
      path {
        stroke: palette.blue(100);
      }
    }

    .btnText {
      @include anyText(palette.blue(100), 19px, 800, 24px);
    }
  }
}
