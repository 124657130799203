@use "src/styles/palette";

.root {
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: 50px;
  height: 50px;
  background-color: palette.blue(100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  z-index: 100;

  &:hover {
    transition: all 0.2s linear;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
}

.icon {
  path {
    fill: palette.white(100);
  }
}
