@use "src/styles/palette";
@import "src/styles/variables.scss";
@import "src/styles/mixins.scss";

.input-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background: palette.grey(17, "old");
  border-radius: $border_radius;
  font-size: 16px;
  color: palette.grey(30, "old");
  font-weight: 600;
  position: relative;
  user-select: none;
  outline: none;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;

    .number-container {
      opacity: 1;
    }
  }

  .eye-container {
    position: absolute;
    right: 19px;
    width: 30px;
    height: 30px;
    @include flex-center;

    &:hover {
      opacity: 0.8;
    }
  }

  .number-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 25px;
    row-gap: 2px;
    cursor: pointer;
    opacity: 0.3;

    .number-up,
    .number-down {
      width: 0;
      height: 0;
      border-style: solid;
    }

    .number-up {
      border-width: 0 6px 9px 6px;
      border-color: transparent transparent palette.blue(15, "old") transparent;
    }

    .number-down {
      border-width: 9px 6px 0 6px;
      border-color: palette.blue(15, "old") transparent transparent transparent;
    }
  }
}

.input {
  border-radius: 8px;
  outline: none;
  background: palette.grey(17, "old") !important;
  width: 100%;
  font-size: 16px;
  color: palette.black(2, "old");
  font-weight: 600;
  padding: 25px 30px 10px 18px;
  box-sizing: border-box;
  border: 1px solid palette.grey(17, "old");
  height: 56px;

  &:hover {
    border: 1px solid palette.blue(100);
  }

  &:focus {
    background: palette.white(100);
    border: 1px solid palette.blue(100);

    &::placeholder {
      color: palette.grey(31, "old");
    }
  }

  &::placeholder {
    color: palette.grey(30, "old");
    opacity: 0.5;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.03em;
    user-select: none;
  }

  &.input-number {
    padding-right: 14px;
  }
}

.input-focused {
  background: palette.white(100) !important;
}

.input-container-error {
  border: 1px solid palette.red(2, "old");

  &:focus,
  &:hover {
    border: 1px solid palette.red(4, "old");
  }
}

.input-label {
  @include title-12px;
  position: absolute;
  top: 10px;
  left: 19px;
}

.input-static {
  background: $secondary_light_color;
}

.input-wrapper {
  width: 377px;
  height: 84px;
  overflow: hidden;
}

.input-error-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  @include anyText(palette.red(2, "old"), 13px, 600, 16px);
  margin: 8px 0 0 0;
  z-index: 1;
}
