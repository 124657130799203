@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  .inputWrapper {
    width: calc(100% - 64px);
  }

  .inputContainer,
  .inputContainer:hover,
  .inputContainer:focus {
    background-color: palette.white(10, "old");
    border-color: palette.white(10, "old");

    input {
      background-color: transparent !important;
    }
  }
  .box {
    width: 464px;
    // height: 692px;
    background: palette.white(100);
    box-shadow: 0 4px 10px -3px palette.black(4, "old");
    border-radius: 16px;
    flex-direction: column;
    padding: 32px 0;
    @include flex-center;
    position: relative;

    .head {
      @include anyText($main_color, 32px, 900, 40px);
      margin: 0 0 68px 0;
      width: calc(100% - 64px);

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 64px);
        height: 1px;
        background-color: palette.grey(100) _2;
        top: 104px;
        left: 32px;
      }
    }

    .actions {
      @include flex-center;
      flex-direction: row-reverse;
      width: 100%;
      column-gap: 16px;
      margin-top: 5px;

      .apply,
      .cancel {
        min-width: 192px;
        height: 56px;
        border-radius: 8px;
        outline: none;

        &:disabled {
          opacity: 0.6
        }
      }

      .apply {
        @include anyText(palette.white(100), 16px, 700, 20px);
        background-color: palette.blue(100);
      }

      .cancel {
        border: 1px solid palette.grey(30, "old");
        background-color: inherit;
        @include anyText(palette.grey(31, "old"), 16px, 700, 20px);
      }
    }
  }
}

.checkbox {
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 32px 64px;
}

.subhead {
  @include anyText(palette.grey(30, "old"), 15px, 400, 20px);
  margin: 0 32px 32px 32px;
  word-break: break-all;
}
