@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  max-width: 1280px;
  width: calc(100% - 28px);
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 2fr 2fr 42px;
  column-gap: 12px;
  padding: 4px 0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 4px;

  &:hover,
  &.focused {
    background-color: palette.white(2, "old");

    .action {
      svg {
        opacity: 1;
      }
    }

    .categories {
      .truncate {
        background: #f8f8f9;
      }
    }
  }

  .info {
    @include align-center;
    column-gap: 9px;
    @include text-overflow;
    padding-left: 10px;
    box-sizing: border-box;

    svg {
      flex-shrink: 0;
    }

    .name {
      @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
      box-sizing: border-box;
      @include text-overflow;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .action {
    svg {
      opacity: 0;
    }
  }

  .modified {
    @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
    @include text-overflow;
  }
}

.categories {
  @include align-center;
  @include text-overflow;
  @include anyText($secondary_color, 14px, 500, 24px);
  position: relative;

  .category {
    @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
    @include text-overflow;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .extraCategories {
    padding: 0 3px;
    box-sizing: border-box;
    @include anyText(palette.grey(072), 12px, 400, 16px);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.status {
  @include anyText(palette.grey(30, "old"), 14px, 500, 24px);
  @include text-overflow;
}
