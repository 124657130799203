@use "src/styles/palette";
@import "./src/styles/mixins.scss";

.root {
  @include align-center;
  column-gap: 24px;
  width: 100%;
  padding: 0 32px 0 24px;
  box-sizing: border-box;
  flex-shrink: 0;

  .folder,
  .scenario {
    @include flex-center;
    column-gap: 8px;
    height: 40px;
  }

  .folder {
    .action {
      @include anyText(palette.blue(100), 16px, 800, 20px);
    }
  }

  .scenario {
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: palette.blue(100);
    border-radius: 8px;

    svg {
      path {
        stroke: white;
      }
    }

    .action {
      @include anyText(palette.white(100), 16px, 800, 20px);
    }
  }
}

@media screen and (max-width: 1024px) {
  @media screen and (max-height: 768px) {
    .root {
      height: 60px;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 640px) {
  .root {
    .scenario,
    .folder {
      padding: 8px;
      position: absolute;
      height: 30px;
      top: -15px;

      .action {
        font-size: 12px;
      }
    }

    .scenario {
      right: -10px;
    }

    .folder {
      right: 90px;
      padding: 0;
    }
  }
}
