@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  position: absolute;
  width: 202px;
  height: 192px;
  top: -40px;
  left: 0;

  &:hover {
    width: 282px;
    height: 192px;
    top: -40px;
    left: -40px;
  }

  &.visibleRight {
    width: 282px;
    height: 192px;
    top: -40px;
    left: -40px;
  }

  // &.selected {
  //   // left: -42px;

  //   .controlItem {
  //     &.left {
  //       // left: 2px;
  //     }
  //   }
  // }

  &.withoutRight &:not(:hover) {
    width: 242px;
    height: 192px;
    right: 0;

    .controlItem {
      &.top,
      &.bottom {
        left: calc(50% - 40px);
      }
    }
  }

  .alreadyCreated {
    .wrapper {
      opacity: 0;
      background-color: #f5f5f5;
      box-shadow: 0px 0px 227px -53px rgba(0, 0, 0, 0.75);
      z-index: -1;
      pointer-events: auto;
      .control {
        z-index: 1;
      }
    }
  }

  &:hover {
    .alreadyCreated {
      .wrapper {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }
}

.controlItem {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  color: palette.white(100);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .wrapper {
      .control {
        width: 24px;
        height: 24px;
        transition: all 0.3s linear;
      }
    }
  }

  .wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .control {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: palette.white(100);
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s linear;
    }
  }

  &.invisible {
    display: none;
  }

  &.top {
    top: 0;
    left: calc(50% - 20px);
  }

  &.bottom {
    bottom: 0;
    left: calc(50% - 20px);
  }

  &.left {
    left: 0;
    top: calc(50% - 20px);
  }

  &.right {
    right: 0;
    top: calc(50% - 20px);
  }

  &.createNew {
    .control {
      background-color: palette.blue(100);
    }
  }

  &.alreadyCreated {
    .control {
      background-color: palette.orange(100);
    }
  }
}
