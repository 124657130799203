@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root,
.withoutIcon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid palette.white(100);
  box-sizing: border-box;

  &.small {
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: bold;
  }

  &.xsmall {
    width: 20px;
    height: 20px;
    font-size: 10px;
    font-weight: bold;
  }
}

.withoutIcon {
  background: palette.white(13, "old");
  color: palette.grey(3, "old");
  text-transform: uppercase;
  @include flex-center;
  line-height: 1;
}
