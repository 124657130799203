@use "src/styles/palette";
@import "src/styles/mixins.scss";

.root {
  @include align-center;
  justify-content: space-between;
  margin: 0 0 16px 0;

  .name {
    @include anyText(palette.grey(30, "old"), 16px, 500, 20px);
    user-select: none;
    @include text-overflow;
  }

  .actions {
    @include align-center;
    column-gap: 15px;
    margin-right: 35px;
  }
}

.icon {
  cursor: pointer;

  path {
    stroke: palette.grey(30, "old");
  }

  &.selected {
    path {
      stroke: palette.blue(100);
    }
  }
}
